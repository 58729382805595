import {reduxConfig as reduxTutoConfig} from "./games/TutoGameConfig";
import {reduxConfig as reduxMainConfig} from "./games/MainGameConfig";
import {reduxConfig as reduxProfileConfig} from "./games/ProfileGameConfig";

// The order of the parameters is FREAKING IMPORTANT : the first one will be the initial game
const reduxGamesConfig = () => ({
    ...reduxTutoConfig(),
    ...reduxMainConfig(),
    ...reduxProfileConfig()
});

export default reduxGamesConfig;
