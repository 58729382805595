export const reduxConfig = () => ({
    main: {
        totalRounds: 10,
        player: {
            playerId: "",
            currentDate: "",
            nobilitas: 5,
            virtus: 5,
            imperium: 0,
            pecunia: 10,
            fortuna: 5,
            walkOnRomaUnlocked: false,
            isExiled: false,
            isPoor: false,
            hasSenateDelegation: false
        },
        opponents: {
            iulianus: {
                isAlive: true,
                isAllied: false,
                isAttackable: false,
                isMurderable: false,
                fortuna: 4,
                nobilitas: 8,
                pecunia: 8,
                virtus: 6,
                imperium: 3
            },
            albinus: {
                isAlive: true,
                isAllied: false,
                isAttackable: false,
                isMurderable: false,
                fortuna: 5,
                nobilitas: 7,
                pecunia: 7,
                virtus: 7,
                imperium: 4
            },
            niger: {
                isAlive: true,
                isAllied: false,
                isAttackable: false,
                isMurderable: false,
                fortuna: 5,
                nobilitas: 5,
                pecunia: 7,
                virtus: 7,
                imperium: 4
            },
            severe: {
                isAlive: true,
                isAllied: false,
                isAttackable: false,
                isMurderable: false,
                fortuna: 9,
                nobilitas: 6,
                pecunia: 7,
                virtus: 7,
                imperium: 6
            }
        },
        dataTransfer:[
            {from: ["playerId"], to: ["player", "playerId"]},
            {from: ["localisation"], to: ["player", "localisation"]}
        ],
        totalRoundsReachedMessage: "Malgré toutes tes manœuvres, un autre a été nommé empereur." +
                                   " Il effacera toute trace de ton personnage de l’histoire…",
        nobilitasToZeroMessage: "Alors que ta gorge commence à te brûler, que ton estomac se révulse et que ta vue commence à se brouiller, tu comprends : à l’instar de Commode, tes adversaires ont choisi le poison pour se débarrasser de toi." +
                                " Tes manœuvres ont créées plus de peurs, de ressentis et de méfiance que de bénéfices pour ta cause…",
        consensusVictoryMessage: "BRAVO : Le consensus t’a désigné empereur ! Une nouvelle dynastie impériale a été créée !",
        militaryVictoryMessage: "BRAVO : La force de tes armées et tes brillantes manœuvres t’ont permis de te débarrasser de tous tes concurrents !" +
                                " Ta dynastie va pouvoir être installée !",
        hasStoryTelling: true,
        redirectToGame: "profile"
    }
});

export const FRAMEWORK_CONFIG = {};
