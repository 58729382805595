export const reduxConfig = () => ({
    profile: {
        totalRounds: 1,
        title: "Choix du profil",
        playerId: "",
        localisation: null,
        redirectToGame: "main",
        totalRoundsReachedMessage: "Vous êtes prêt !",
        dataTransfer:[
            {from: ["player"], to: ["player"]},
            {from: ["opponents"], to: ["opponents"]}
        ],
    }
});

export const FRAMEWORK_CONFIG = {};
