import {SET_PLAYER} from "../actions/main/setPlayer";
import {SET_OPPONENTS} from "../actions/main/setOpponents";
import {SET_STATE} from "../actions/setState";

const main = (state = 0, action) => {
    switch (action.type) {
        case SET_PLAYER:
            return { ...state, player: action.player };
        case SET_OPPONENTS:
            return { ...state, opponents: action.opponents };
        case SET_STATE:
            if(action.state.main != null) {
                return action.state.main;
            } else {
                return 0;
            }
        default:
            return state;
    }
};

export default main;
