import reduxGamesConfig from "./GamesConfig";

const REDUX_GLOBAL_CONFIG = {
    currentCode: null, // GENERIC
    executedCodes: [], // GENERIC (array<string>)
    currentRound: 1, // GENERIC,
    errorCodeMessage: null,
    availablePlayers: {
        titus: {
            fullName: "Titus Flavius Scaro",
            intro: "Sénateur à Rome, 40 ans, ancien consul et à ce titre sénateur.",
            description: "Décidé à tenter sa chance dans la succession de Pertinax à la fille duquel il est marié. Situé à Rome, au plus proche du pouvoir et du sénat auprès duquel il jouit d’une forte influence.",
            localisation: "rome"
        },
        gaius: {
            fullName: "Gaius Antonius Firminus",
            intro: "Gouverneur de province armée, 45 ans, sénateur, tribun militaire, proconsul puis actuellement légat de Mésie Inférieure.",
            description: "Un nouvel usurpateur prêt à tenter sa chance ! Situé en Mésie Inférieure (entre les actuelles Serbie, Bulgarie et Roumanie) loin de Rome mais à la tête d'une légion et soutenu par les élites provinciales.",
            localisation: "province"
        }
    }
};

export const getInitialGame = () => {
    return Object.keys(reduxGamesConfig())[0];
};

export default REDUX_GLOBAL_CONFIG;
