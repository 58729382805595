// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-code-consequences-js": () => import("./../../../src/pages/code/consequences.js" /* webpackChunkName: "component---src-pages-code-consequences-js" */),
  "component---src-pages-code-decision-js": () => import("./../../../src/pages/code/decision.js" /* webpackChunkName: "component---src-pages-code-decision-js" */),
  "component---src-pages-code-introduction-js": () => import("./../../../src/pages/code/introduction.js" /* webpackChunkName: "component---src-pages-code-introduction-js" */),
  "component---src-pages-code-js": () => import("./../../../src/pages/code.js" /* webpackChunkName: "component---src-pages-code-js" */),
  "component---src-pages-fin-js": () => import("./../../../src/pages/fin.js" /* webpackChunkName: "component---src-pages-fin-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-voyage-js": () => import("./../../../src/pages/voyage.js" /* webpackChunkName: "component---src-pages-voyage-js" */)
}

