import {createStore} from "redux";
import combinedReducer from './reducers/combinedReducers'
import {getState} from "./stateConstructor";
import {getInitialGame} from "../config/GlobalConfig";

const LOCAL_STORAGE_NAME = "lugdunumLocalData";
const EXPIRATION_TOKEN_NAME = "lugdnunumExpirationToken";

class PersistedStore {
    // Singleton property
    static DefaultStore = null;

    // Redux store
    _store = null;

    // When class instance is used, initialize the store
    constructor() {
        this.initStore()
    }

    // Accessor to the default instance of this class
    static getDefaultStore() {
        if (PersistedStore.DefaultStore === null) {
            PersistedStore.DefaultStore = new PersistedStore();
        }

        return PersistedStore.DefaultStore;
    }

    // Initialization of Redux Store
    initStore() {
        this._store = createStore(combinedReducer, PersistedStore.loadState());
        this._store.subscribe(() => {
            PersistedStore.saveState(this._store.getState());
        });
    }

    // Getter to access the Redux store
    get store() {
        return this._store;
    }

    // Loading persisted state from localStorage, no need to access
    // this method from the outside
    static loadState() {
        try {
            let serializedState = localStorage.getItem(LOCAL_STORAGE_NAME);
            let expirationToken = localStorage.getItem(EXPIRATION_TOKEN_NAME);

            if (serializedState === null || expirationToken === null) {
                return PersistedStore.initialState();
            }

            let localStorageValidity = this.checkLocalStorageValidity(JSON.parse(expirationToken));
            if (!localStorageValidity) {
                localStorage.removeItem(LOCAL_STORAGE_NAME);
                localStorage.removeItem(EXPIRATION_TOKEN_NAME);
                return PersistedStore.initialState();
            }

            return JSON.parse(serializedState);
        } catch (err) {
            return PersistedStore.initialState();
        }
    }

    // Compare now's timestamp with previously saved timestamp corresponding to local storage
    static checkLocalStorageValidity(expirationToken) {
        let now = new Date().getTime();
        return now <= expirationToken.timestamp;
    }

    // Saving persisted state to localStorage every time something
    // changes in the Redux Store (This happens because of the subscribe()
    // in the initStore-method). No need to access this method from the outside
    static saveState(state) {
        try {
            let serializedState = JSON.stringify(state);
            localStorage.setItem(LOCAL_STORAGE_NAME, serializedState);
            if (localStorage.getItem(EXPIRATION_TOKEN_NAME) === null) {
                let expirationDate = new Date();
                expirationDate.setHours(expirationDate.getHours() + 24);
                let expirationToken = {name: EXPIRATION_TOKEN_NAME, timestamp: expirationDate.getTime()};
                localStorage.setItem(EXPIRATION_TOKEN_NAME, JSON.stringify(expirationToken));
            }
        } catch (err) {}
    }

    static initialState() {
        return getState(getInitialGame());
    }
}

export default PersistedStore;
