import reduxGamesConfig from "../config/GamesConfig";
import REDUX_GLOBAL_CONFIG from "../config/GlobalConfig";

export const getState = (type) => {
    let state = {
        global: {
            ...REDUX_GLOBAL_CONFIG,
            type: type,
        }
    };

    state[type] = {
        ...reduxGamesConfig()[type]
    };

    return state;
};
