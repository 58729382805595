import {SET_CODE_LIST} from "../actions/global/setCodeList";
import {SET_ROUNDS_DATA} from "../actions/global/setRoundsData";
import {SET_CURRENT_CODE} from "../actions/global/setCurrentCode";
import {INCREMENT_CURRENT_ROUND} from "../actions/global/incrementCurrentRound";
import {ADD_TO_EXECUTED_CODES} from "../actions/global/addToExecutedCodes";
import {SET_CONSEQUENCES} from "../actions/global/setConsequences";
import {SET_STATE} from "../actions/setState";
import {SET_ERROR_CODE_MESSAGE} from "../actions/global/setErrorCodeMessage";

const global = (state = 0, action) => {
    switch (action.type) {
        case SET_CODE_LIST:
            return { ...state, codeList: action.codeList };
        case SET_ROUNDS_DATA:
            return { ...state, roundsData: action.roundsData };
        case SET_CURRENT_CODE:
            return { ...state, currentCode: action.currentCode };
        case INCREMENT_CURRENT_ROUND:
            return { ...state, currentRound: state.currentRound + 1 };
        case ADD_TO_EXECUTED_CODES:
            return { ...state, executedCodes: [...state.executedCodes, ...[action.code]] };
        case SET_CONSEQUENCES:
            return { ...state, consequences: action.consequences };
        case SET_ERROR_CODE_MESSAGE:
            return { ...state, errorCodeMessage: action.errorCodeMessage };
        case SET_STATE:
            return {...state, ...action.state.global};
        default:
            return state;
    }
};

export default global;
