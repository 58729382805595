export const reduxConfig = () => ({
    tuto: {
        totalRounds: 6,
        title: "Introduction",
        playerId: "",
        localisation: null,
        redirectToGame: "main",
        totalRoundsReachedMessage: "Vous êtes prêt !"
    }
});

export const FRAMEWORK_CONFIG = {};
