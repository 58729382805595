import {SET_PLAYER_ID} from "../actions/tuto/setPlayerId";
import {SET_STATE} from "../actions/setState";
import {SET_LOCALISATION} from "../actions/tuto/setLocalisation";

const tuto =  (state = 0, action) => {
    switch (action.type) {
        case SET_PLAYER_ID:
            return { ...state, playerId: action.playerId };
        case SET_LOCALISATION:
            return { ...state, localisation: action.localisation };
        case SET_STATE:
            if(action.state.profile != null) {
                return action.state.profile;
            } else {
                return 0;
            }
        default:
            return state;
    }
};

export default tuto;
